import React from 'react';
import ReactDOM from 'react-dom';
import Input from "./App";

ReactDOM.render(
  <React.StrictMode>
    <Input />
  </React.StrictMode>,
  document.getElementById('react-input')
);


