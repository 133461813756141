import React, {useRef} from "react";
import Onboard from 'bnc-onboard'
import Web3 from "web3"
import {ABI, contractAddress} from "./abi";

const priceWei = "50000000000000000"
function Input() {
    let inputValue = useRef("0")
    const checkValueInput = () => {
        if (+inputValue.current.value < 1 || +inputValue.current.value > 15) inputValue.current.value = ""
    }
    let web3;

    const onboard = Onboard({
        dappId: "7cbfa4df-b2fa-464d-ba2c-edb5f9da89fd",       // [String] The API key created by step one above
        networkId: 1,  // [Integer] The Ethereum network ID your Dapp uses.
        darkMode: true,
        subscriptions: {
            wallet: wallet => {
                web3 = new Web3(wallet.provider)
            }
        },
        walletSelect: {
            wallets: [{
                walletName: "walletConnect",
                infuraKey: "30ea44a7f7a54d0d899346ed5a0929fd",
                preferred: true
            },
                {walletName: "metamask", preferred: true}
            ]
        }
    });

    const transaction = async () => {
        try {
            await onboard.walletSelect()
            await onboard.walletCheck()
            const contract = new web3.eth.Contract(ABI, contractAddress);
            const {address} = onboard.getState();
            const encodeABI = await contract.methods.buyTokens(address, inputValue.current.value).encodeABI()
            const price = +inputValue.current.value * priceWei
            const gasLimit = 200_000 * +inputValue.current.value
            await web3.eth.sendTransaction({
                from: address,
                to: contractAddress,
                value: price,
                gas: gasLimit,
                data: encodeABI
            })
        } catch (e) {
            console.log(e)
        }

    };
    return (
        <div className="custom-search">
            <input type="number" ref={inputValue} onInput={checkValueInput}
                className="custom-mint-input draw"
                placeholder="enter a number between 1 and 15"
            />
            <button onClick={transaction} id="btn-connect" className="custom-mint-button " type="submit">
                Mint Token
            </button>
        </div>
    );
}

export default Input;
